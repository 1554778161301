import React from "react";
import theme from "theme";
import { Theme, Text, Box, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Pizzeria Pizza Przystan
			</title>
			<meta name={"description"} content={"Zanurz się w naszym świecie aromatycznej pizzy pieczonej do perfekcji i pozwól swoim kubkom smakowym wyruszyć w kulinarną podróż niezrównaną pod względem smaku i świeżości."} />
			<meta property={"og:title"} content={"Pizzeria Pizza Przystan"} />
			<meta property={"og:description"} content={"Zanurz się w naszym świecie aromatycznej pizzy pieczonej do perfekcji i pozwól swoim kubkom smakowym wyruszyć w kulinarną podróż niezrównaną pod względem smaku i świeżości."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/Pizza_Muenchen_Teaser.jpg?v=2024-06-13T13:23:28.679Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/Pizza_Muenchen_Teaser.jpg?v=2024-06-13T13:23:28.679Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/Pizza_Muenchen_Teaser.jpg?v=2024-06-13T13:23:28.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/Pizza_Muenchen_Teaser.jpg?v=2024-06-13T13:23:28.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/Pizza_Muenchen_Teaser.jpg?v=2024-06-13T13:23:28.679Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/Pizza_Muenchen_Teaser.jpg?v=2024-06-13T13:23:28.679Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666ac29b3f24780021f7d54f/images/Pizza_Muenchen_Teaser.jpg?v=2024-06-13T13:23:28.679Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-10">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Text margin="0px 0px 15px 0px" font="normal 700 42px/1.2 --fontFamily-sans" text-align="center">
				Przegląd menu
			</Text>
			<Text margin="0px 0px 50px 0px" font="--lead" color="#626970" text-align="center">
				Tutaj każde danie opowiada historię smaku, stworzoną z precyzją i głębokim szacunkiem dla włoskich tradycji kulinarnych. Od miłośnika pizzy szukającego klasycznych wrażeń po żądnego przygód smakosza szukającego nowych kombinacji, nasze menu zostało zaprojektowane tak, aby zaspokoić każdy gust.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="24px"
				lg-grid-gap="25px 25px"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1600028068383-ea11a7a101f3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) center/cover repeat scroll padding-box"
					height="250px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						align-items="center"
						margin="0px 0px 10px 0px"
						lg-margin="0px 0px 15px 0px"
					/>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Klasyczne pizze
						</Text>
						<Text
							font="normal 400 17px/1.5 --fontFamily-sansHelvetica"
							color="--lightD2"
							margin="10px 0 0 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
						>
							Margarita: Świeża mozzarella, bazylia, pomidory.{"\n"}
							<br />
							Pepperoni: Pepperoni.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1534308983496-4fabb1a015ee?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Dla smakoszy
						</Text>
						<Text
							font="normal 400 17px/1.5 --fontFamily-sansHelvetica"
							color="--lightD2"
							margin="10px 0 0 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
						>
							Trufla z grzybami: Pieczarki, oliwa truflowa.{"\n"}
							<br />
							BBQ z kurczakiem: sos BBQ, kurczak, czerwona cebula.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1617343251257-b5d709934ddd?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Wegetariańska pizza
						</Text>
						<Text
							font="normal 400 17px/1.5 --fontFamily-sansHelvetica"
							color="--lightD2"
							margin="10px 0 0 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
						>
							Veggie Supreme: Papryka, cebula, grzyby, oliwki.{"\n"}
							<br />
							Szpinak i feta: Feta, szpinak.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1563379926898-05f4575a45d8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Z wyjątkiem pizzy
						</Text>
						<Text
							font="normal 400 17px/1.5 --fontFamily-sansHelvetica"
							color="--lightD2"
							margin="10px 0 0 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
						>
							Przekąski: Rozpocznij posiłek od naszego pieczywa czosnkowego lub świeżej sałatki caprese.{"\n"}
							<br />
							Makaron: Poznaj nasze makarony, od obfitej lasagne po delikatne linguine z owocami morza.{"\n"}
							<br />
							Desery: Żaden posiłek nie jest kompletny bez słodkiego akcentu. Spróbuj naszego tiramisu lub domowych lodów.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text">
				Sprawdź wszystkie nasze oferty
			</Override>
			<Override slot="text1">
				Ta recenzja to tylko ułamek tego, co Pizzeria Pizza Przystan ma w swoim menu. Zapraszamy do odwiedzenia nas i poznania pełnej oferty naszych dań. Każde danie przygotowywane jest ze szczególną starannością, tak aby każdy Gość znalazł coś dla siebie i swoich pragnień oraz potrzeb dietetycznych. Ta recenzja to tylko ułamek tego, co Pizzeria Pizza Przystan ma w swoim menu. Zapraszamy do odwiedzenia nas i poznania pełnej oferty naszych dań. Każde danie przygotowywane jest ze starannością, tak aby każdy Gość znalazł coś dla siebie i swoich pragnień oraz potrzeb dietetycznych.
			</Override>
			<Override slot="box2" align-self="center" />
		</Components.Cta>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6666e55e1d712a002334ca50"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});